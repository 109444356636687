





















import useAuthPermission from "@/use/authPermissions";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAccessPointsList: () =>
      import(
        "@/components/organisms/event/accessControl/o-access-points-list.vue"
      ),
  },
  setup(_, { root }) {
    const { accessControlManagement } = useAuthPermission({ root });

    return { accessControlManagement };
  },
});
